import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import homeuser from '../../assets/Ugroimages/homeuser.svg';
import homedown from '../../assets/Ugroimages/homedown.svg';
import logo from '../../assets/Ugroimages/logo.png';
import mobilemenu from '../../assets/Ugroimages/mobile-menu.svg';
import crossicon from '../../assets/Ugroimages/croses.svg';
import '../../index.css';
import './style.scss';
import { Link } from "react-router-dom";
import $ from "jquery";
import Button from "../UI/Button/Button";
import CryptoJS from 'crypto-js'
import notify from "../../shared/notify";
import { useStores } from "../../stores";

const Header = (props) => {
  const navigate = useNavigate();
  const { applicantStore } = useStores();

  const [rmName, setRMName] = useState('')
  const [rmEmail, setRMEmail] = useState('')

  let loginToken = sessionStorage.getItem('token')
  var bytes = CryptoJS.AES.decrypt(loginToken, `${process.env.REACT_APP_ENCRYPTION_KEY}`);
  var originalToken = bytes.toString(CryptoJS.enc.Utf8);

  let loginType = sessionStorage.getItem('logintype')
  var bytes = CryptoJS.AES.decrypt(loginType || '', `${process.env.REACT_APP_ENCRYPTION_KEY}`);
  var originalLoginType = bytes.toString(CryptoJS.enc.Utf8);

  const handleLogOut = async () => {
    if (originalLoginType == 'Email') {
      applicantStore.logout(originalToken, (response) => {
        if (response?.success) {
          notify(`${response?.message}`, 'success')
          sessionStorage.clear()
          window.location.href = '/'
        } else {
          notify(`${response?.error}`, 'danger')
        }
      })
    } else {
      sessionStorage.removeItem('logRecordCount');
      sessionStorage.removeItem('token');
      window.location.href = '/'
    }
  }

  let name = sessionStorage.getItem('name')
  var bytes = CryptoJS.AES.decrypt(name, `${process.env.REACT_APP_ENCRYPTION_KEY}`);
  var originalNameText = bytes.toString(CryptoJS.enc.Utf8);

  let email = sessionStorage.getItem('email')
  var bytes = CryptoJS.AES.decrypt(email, `${process.env.REACT_APP_ENCRYPTION_KEY}`);
  var originalEmailText = bytes.toString(CryptoJS.enc.Utf8);
  useEffect(() => {
    setRMName(originalNameText);
    setRMEmail(originalEmailText)
  }, [])


  const MenuClick = () => {
    $(".wrapper").toggleClass("active");
  }
  return (
    <header className="HeaderSection">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-xs-6 text-left logo-container">
            <div className="mobile-menu">
              <img onClick={MenuClick} src={mobilemenu} className="img-fluid mobile-memu-img" alt="" />
            </div>
            <div className="logo-img">
              <img src={logo} className="img-fluid logo" alt="Logo" />
            </div>
          </div>
          <div className="col-md-6 col-6 text-right pr-md-30">
            <div className="dropdown custom-dropdown-style">
              <button type="button" style={{ width: '250px' }} className="dropbtn " data-toggle="dropdown" id="dropdownMenuButton" aria-expanded="false">
                <div>
                  <img src={homeuser} className="img-fluid homeuser" alt="User" />
                  Hi {rmName}
                </div>
                <img src={homedown} className="img-fluid left" alt="User Down" />
              </button>
              <div className="dropdown-menu dropdown-content fixHeaderDropdown" aria-labelledby="dropdownMenuButton" x-placement="bottom-start">
                <img src={crossicon} className="img-fluid homecross" alt="User" data-toggle="dropdown" id="dropdownMenuButton" aria-expanded="false" />
                <div className="login-user-content">
                  <img src={homeuser} className="img-fluid homeuser details" alt="User" />
                  <p className="user-name">{rmName}</p>
                  <p className="user-email">{rmEmail}</p>
                  {/* <p className="dropdown-item user-email">{rmEmail}</p> */}
                  <div className="logout-box">
                    <Link to="" className="user-logout" onClick={handleLogOut} >Logout</Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <Button
              type="button"
              buttonStyle="btn--secondary--solid"
              buttonSize="btn-medium"
              onClick={handle}
            >Logout</Button> */}
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;
