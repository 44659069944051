import axios from 'axios';
import { GetBaseUrl } from '../service/helper';
import encryption from "../../Helper/encryption";
import decryption from "../../Helper/decryption";

const baseUrl = GetBaseUrl();

class Service {
    constructor(props) {
        this.refreshHeader(() => {

        });
    }

    refreshHeader(callback) {
        const sessionToken = sessionStorage.getItem('token');
        let srh = {
            headers: {
                Authorization: `Bearer ${sessionToken}`,
                Accept: 'application/json',
            }
        };
        let service = axios.create(srh);
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
        return callback();
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        let code = 400;
        let message = '';
        if (typeof error.response != 'undefined') {
            if (typeof error.response?.data?.meta?.code != 'undefined') {
                code = error.response.data.meta.code;
                message = error.response.data.meta.message;
            }
            if (error.response.statusText === "Unauthorized") {
                code = error.response.status;
                message = error.response.statusText;
            }
        }

        switch (code) {
            case 401:
                //EventRegister.emit('SessionExpiredEvent', 'Session Expired');  
                sessionStorage.removeItem('token');
                window.location.href = `${process.env.PUBLIC_URL}/`;
                break;
            case 404:
                //alert('Resource not found');
                break;
            default:
                //alert(JSON.stringify(error.response.data.error.replace(/['"]+/g, '')));
                // alert('Internal server error, please retry');
                if (message != '') {
                    // notify(message, 'danger');
                }
                break;
        }
        return Promise.reject(error)
    }

    redirectTo = (document, path) => {
        document.location = path
    }

    async get(path, callback) {
        var response = await this.service.get(`${baseUrl}/${path}`);
        let decryptedResponse = decryption(response, path);
        callback(decryptedResponse.status, decryptedResponse.data);
        return decryptedResponse;
    }

    async patch(path, payload, callback) {
        let response = await this.service.request({
            method: 'PATCH',
            url: `${baseUrl}/${path}`,
            responseType: 'json',
            data: payload
        });
        return callback(response.status, response.data);
        // return response;
    }

    async post(path, payload, callback) {
        let encryptedData = encryption(payload);
        let response = await this.service.request({
            method: "POST",
            url: `${baseUrl}/${path}`,
            responseType: "json",
            data: encryptedData
        });
        let decryptedResponse = await decryption(response, payload);
        return callback(decryptedResponse);
        // return response;
    }

    async lsqPost(path, payload, callback) {
        let response = await this.service.request({
            method: "POST",
            url: `${baseUrl}/${path}`,
            responseType: "json",
            data: payload
        });
        let decryptedResponse = await decryption(response, payload);
        return callback(decryptedResponse);
    }

    async lsqGet(path, callback) {
        var response = await this.service.get(`${baseUrl}/${path}`);
        // let decryptedResponse = decryption(response, path);
        // callback(decryptedResponse.status, decryptedResponse.data);
        return callback(response);
    }

    async put(path, payload, callback) {
        let response = await this.service.request({
            method: 'PUT',
            url: `${baseUrl}/${path}`,
            responseType: 'json',
            data: payload
        });
        callback(response.status, response.data);
        return response;
    }
}

export default new Service;  