import React from "react";
import loginbg from '../../assets/Ugroimages/loginbg.webp';
import logo from '../../assets/Ugroimages/logo.png'
import '../../index.css';
import './style.scss';

const LoginContainer = (props) => {
    return (
        <div className="Login-container">
            <div className="container-fluid bg-lightgrey">
                <div className="row">
                    <div className="col-md-7 col-sm-12 px-0 login-banner">
                        <img
                            style={{ width: '100%', height: '100vh' }}
                            src={loginbg}
                            alt="NachDark"
                        />
                    </div>
                    <div className="col-md-5 text-center col-sm-12 mob-container">
                        <div className="LoginForm-container">
                            <div className="LoginForm">
                                <div className="logo-img">
                                    <img src={logo} className="img-fluid logo" alt="" />
                                </div>
                                <div className="login-header">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LoginContainer;
