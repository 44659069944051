// const fileType = require('file-type')
// import notify from "../components/notify";
import { makeAutoObservable } from "mobx";
import Service from "../shared/serrvice/service";
import axios from 'axios';
import { GetBaseUrl, GetServiceTypeBaseUrl, GetXApiKey } from '../shared/service/helper';
import decryption from "../Helper/decryption";
import notify from "../shared/notify";
const baseUrl = GetBaseUrl();
const serviceTypeUrl = GetServiceTypeBaseUrl()
const apiKey = GetXApiKey();


class applicantStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    serviceMod = {
        loading: false,
        response: false,
        input: {
            lan: "",
            subModuleId: "",
            description: "",
            documents: [],
        },
    };

    serviceRequestDataModel = {
        loading: false,
        data: {}
    }

    customerDataModel = {
        loading: false,
        data: {}
    }

    serviceRequestCommentDataModel = {
        requestLaoding: false,
        errorMessage: "",
        data: '',
    }

    downloadFileModel = {
        loading: false
    }

    async submitLsqServiceRequest(payload, callback, failure) {
        this.serviceMod.loading = true;

        await Service.lsqPost(`create-lsq-ticket`, payload,
            async (response) => {
                this.serviceMod.loading = false;
                // let originalResponse = response?.data?.original?.data
                callback(response)
            }
        )
            .catch((error) => {
                this.serviceMod.loading = false;
                failure(error)
            })

    }

    async submitServiceRequest(payload, uploadedFile, callback, failure) {
        this.serviceMod.loading = true;

        await Service.post(`jiraRequest`, payload,
            async (response) => {
                this.serviceMod.loading = false;
                let originalResponse = response?.data?.original?.data

                for (let i = 0; i < uploadedFile.length; i++) {
                    await axios.post(`${baseUrl}/fileAttachToIssue/${originalResponse.id}`, uploadedFile[i]);
                }

                callback(originalResponse)
            }
        )
            .catch((error) => {
                this.serviceMod.loading = false;
                failure(error)

            })

    }

    async getServiceRequestData(data, callback) {
        this.serviceRequestDataModel.loading = true;
        await Service.post(
            "jiraRequest",
            data,
            async (response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response.data)

            }
        ).catch((error) => {
            this.serviceMod.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }

    async getCustomerData(data, callback) {
        this.customerDataModel.loading = true;
        try {
            let res = await axios.post(`${baseUrl}/icrmGetCustomerData`, data)
            let decryptedResponse = await decryption(res);
            callback(decryptedResponse?.data);
        } catch (error) {
            this.serviceMod.loading = false;
            this.customerDataModel.data = [];
        };
    }

    async getServiceTypeList(callback) {

        this.serviceRequestDataModel.loading = true;
        await Service.get(
            "getServiceCategories",
            async (status, response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response);
            }
        ).catch((error) => {
            this.serviceRequestDataModel.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }

    async updateIsRegisteredMail(data, callback) {
        this.serviceRequestDataModel.loading = true;
        await Service.post(
            "jiraRequest",
            data,
            async (response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response.data)

            }
        ).catch((error) => {
            this.serviceMod.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }

    getServiceRequestCommentData(data, callback) {
        this.serviceRequestCommentDataModel.requestLaoding = true;
        Service.post(
            "jiraRequest",
            data,
            async (response) => {
                this.serviceRequestCommentDataModel.requestLaoding = false;
                if (response) {
                    callback(response.data)
                } else {
                    // notify(response.data, 'danger');
                }

            }
        ).catch((error) => {
            this.serviceRequestCommentDataModel.requestLaoding = false;
        });
    }

    async getUploadedFile(callback) {
        this.downloadFileModel.loading = true;
        try {
            let res = await axios.get(`${baseUrl}/getAttachment/${callback}`)
            this.downloadFileModel.loading = false;
            // var signatures = {
            //     JVBERi0: "application/pdf",
            //     R0lGODdh: "image/gif",
            //     R0lGODlh: "image/gif",
            //     iVBORw0KGgo: "image/png",
            //     "/9j/": "image/jpg",
            //     UEs: "application/vnd.openxmlformats-officedocument.",
            // };
            // function detectMimeType(b64) {
            //     for (var s in signatures) {
            //         if (b64.indexOf(s) === 0) {
            //             return signatures[s];
            //         }
            //     }
            // }


            let ext = res.data.extension;

            let type = getFileExtention(ext);

            const byteCharacters = atob(res.data.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: type });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Uploaded_File${callback}`;
            link.click();
            return res;
        } catch (error) {
            this.downloadFileModel.loading = false;
        }

    }

    async saveUserDetails(data, callback) {
        await Service.post(
            "login-records",
            data,
            async (response) => {
                sessionStorage.setItem('logRecordCount', 1)
                callback(response.data)
            }
        ).catch((error) => {
            this.serviceMod.loading = false;
        });
    }

    async loginWithEmail(data, callback) {
        await Service.post(
            "crm-login",
            data,
            async (response) => {
                callback(response.data)
            }
        ).catch((error) => {
            this.serviceMod.loading = false;
        });
    }

    async loginWithEmailSendOtp(data, callback) {
        await Service.post(
            "crm-send-otp",
            data,
            async (response) => {
                callback(response.data)
            }
        ).catch((error) => {
            this.serviceMod.loading = false;
        });
    }

    async verifyLoginWithEmail(data, callback) {
        await Service.post(
            "crm-validate-otp",
            data,
            async (response) => {
                callback(response.data)
            }
        ).catch((error) => {
            this.serviceMod.loading = false;
        });
    }

    async sendResetLinkToEmail(data, callback) {
        await Service.post(
            "crm-forget-password",
            data,
            async (response) => {
                callback(response.data)
            }
        ).catch((error) => {
            this.serviceMod.loading = false;
        });
    }

    async resetPassword(data, callback) {
        await Service.post(
            "reset-password",
            data,
            async (response) => {
                callback(response.data)
            }
        ).catch((error) => {
            this.serviceMod.loading = false;
        });
    }

    async logout(originalToken, callback) {
        await Service.post("crm-logout", { token: originalToken }, async (response) => {
            callback(response.data)
        }).catch((error) => {
            notify("Server Error, Please try again", "danger");
        });
    }

    async getLsqServiceRequestData(requestData, callback, failure) {
        this.serviceRequestDataModel.loading = true;
        await Service.post(
            "get-ticket-count",
            requestData,
            async (response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response?.data)
            }
        ).catch((error) => {
            failure(error)
            this.serviceMod.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }

    async getTicketDetalis(payloadData, callback) {
        this.serviceRequestDataModel.loading = true;
        await Service.post(
            "lsq-ticket-details",
            payloadData,
            async (response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response)
            }
        ).catch((error) => {
            callback(error?.response)
            this.serviceMod.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }

    async getLsqTicketComments(payloadData, callback) {
        this.serviceRequestDataModel.loading = true;
        await Service.post(
            `get-activity`,
            payloadData,
            async (response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response)
            }
        ).catch((error) => {
            callback(error?.response)
            this.serviceMod.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }

    async getServiceLsqJiraRequestData(payloadData, callback) {
        this.serviceRequestDataModel.loading = true;
        await Service.post(
            `search-filter`,
            payloadData,
            async (response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response)
            }
        ).catch((error) => {
            callback(error?.response)
            this.serviceMod.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }

    async checkIsLsqActive(callback) {
        this.serviceRequestDataModel.loading = true;
        await Service.get(
            `is-lsq-active`,
            async (status, response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response)
            }
        ).catch((error) => {
            callback(error?.response)
            this.serviceMod.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }

    async getLsqTicketUploadedFiles(fileId, callback) {
        this.serviceRequestDataModel.loading = true;
        await Service.get(
            `get-file-details/${fileId}`,
            async (status,response) => {
                this.serviceRequestDataModel.loading = false;
                callback(response)
            }
        ).catch((error) => {
            callback(error?.response)
            this.serviceMod.loading = false;
            this.serviceRequestDataModel.data = [];
        });
    }
}

const getFileExtention = (ext) => {
    switch (ext) {
        case "doc": return "application/msword";
        case "dot": return "application/msword";
        case "docx": return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "dotx": return "application/vnd.openxmlformats-officedocument.wordprocessingml.template";
        case "docm": return "application/vnd.ms-word.document.macroEnabled.12";
        case "dotm": return "application/vnd.ms-word.template.macroEnabled.12";
        case "xls": return "application/vnd.ms-excel";
        case "xlt": return "application/vnd.ms-excel";
        case "xla": return "application/vnd.ms-excel";
        case "xlsx": return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "xltx": return "application/vnd.openxmlformats-officedocument.spreadsheetml.template";
        case "xlsm": return "application/vnd.ms-excel.sheet.macroEnabled.12";
        case "xltm": return "application/vnd.ms-excel.template.macroEnabled.12";
        case "xlam": return "application/vnd.ms-excel.addin.macroEnabled.12";
        case "xlsb": return "application/vnd.ms-excel.sheet.binary.macroEnabled.12";
        case "ppt": return "application/vnd.ms-powerpoint";
        case "pot": return "application/vnd.ms-powerpoint";
        case "pps": return "application/vnd.ms-powerpoint";
        case "ppa": return "application/vnd.ms-powerpoint";
        case "pptx": return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        case "potx": return "application/vnd.openxmlformats-officedocument.presentationml.template";
        case "ppsx": return "application/vnd.openxmlformats-officedocument.presentationml.slideshow";
        case "ppam": return "application/vnd.ms-powerpoint.addin.macroEnabled.12";
        case "pptm": return "application/vnd.ms-powerpoint.presentation.macroEnabled.12";
        case "potm": return "application/vnd.ms-powerpoint.template.macroEnabled.12";
        case "ppsm": return "application/vnd.ms-powerpoint.slideshow.macroEnabled.12";
        case "mdb": return "application/vnd.ms-access";
        case "gif": return "image/gif"
        case "png": return "image/png"
        case "jpg":
        case "jpeg":
            return "image/jpg"
        case "pdf": return "application/pdf"
    }
}


export default applicantStore;
