import React from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import '../../index.css'

const SidebarHeader = (props) => {
    return (
        <div className="bg-lightgrey">
            <Header />
            <Sidebar />
        </div>
    );
}

export default SidebarHeader;