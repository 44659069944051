import React, { Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoginContainer from '../../../components/LoginContainer/LoginContainer'
import Button from '../../../components/UI/Button/Button'
import { useStores } from '../../../stores'
import notify from '../../../shared/notify'

const ResetPassword = () => {

    const token = useParams().token
    const { applicantStore } = useStores();
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = React.useState(false);
    const [confPassword, setConfPassword] = useState('')
    const [confShowPassword, setConfShowPassword] = React.useState(false);
    const [loading, setLoading] = useState(false)

    const handleResetPassword = async () => {
        if (email === '') {
            notify('Please enter email', 'danger');
            return;
        }
        if (password === '') {
            notify('Please enter new password', 'danger');
            return;
        }
        if (confPassword === '') {
            notify('Please enter confirm password', 'danger');
            return;
        }
        setLoading(true)
        let requestData = {
            "email": email,
            "password": password,
            "password_confirmation": confPassword,
            "token": token
        }
        applicantStore.resetPassword(requestData, (response) => {
            if (response?.success) {
                notify(`${response?.message}`, 'success')
                setLoading(false)
                setTimeout(() => {
                    navigate('/')
                }, 5000);
            } else {
                notify(`${response?.message}`, 'danger')
                setLoading(false)
            }
        })
        setLoading(false)
    }

    return (
        <Fragment>
            <LoginContainer>
                <div className='login-box mx-auto'>
                    <h2 className="top-heading text-center">Reset Password</h2>
                    <div className='form-group mt-3'>
                        <label>Email</label><tt className="text-danger">*</tt>
                        <input
                            value={email}
                            type='email'
                            onChange={e => setEmail(e.target.value)}
                            className='form-control'
                            placeholder={'Email'}
                        />
                    </div>
                    <div className='form-group mt-3'>
                        <label>New Password</label><tt className="text-danger">*</tt>
                        <div className="d-flex justify-content-center align-items-center">
                            <input
                                value={password}
                                type={showPassword ? "text" : "password"}
                                onChange={e => setPassword(e.target.value)}
                                className='form-control'
                                placeholder={'Password'}
                            />
                            <i
                                onClick={() => setShowPassword(!showPassword)}
                                className={`fa ${!showPassword ? "fa-eye-slash" : "fa-eye"}`}
                                style={{ zIndex: '999', position: 'absolute', right: '105px' }}
                            ></i>
                        </div>
                    </div>
                    <div className='form-group mt-3'>
                        <label>Confirm Password</label><tt className="text-danger">*</tt>
                        <div className="d-flex justify-content-center align-items-center">
                            <input
                                value={confPassword}
                                type={confShowPassword ? "text" : "password"}
                                onChange={e => setConfPassword(e.target.value)}
                                className='form-control'
                                placeholder={'Confirm Password'}
                            />
                            <i
                                onClick={() => setConfShowPassword(!confShowPassword)}
                                className={`fa ${!confShowPassword ? "fa-eye-slash" : "fa-eye"}`}
                                style={{ zIndex: '999', position: 'absolute', right: '105px' }}
                            ></i>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mr-2 mt-24">
                        <Button
                            type="submit"
                            buttonStyle="btn--secondary--solid"
                            buttonSize="btn-medium"
                            onClick={handleResetPassword}
                        >
                            {!loading ? 'Update Password' : <div className="loading-spinner" style={{ width: '20px', height: '20px' }}></div>}
                        </Button>
                    </div>
                </div>
            </LoginContainer>
        </Fragment>
    )
}

export default ResetPassword