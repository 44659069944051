import CryptoJS from "crypto-js";

const encKey = process.env.REACT_APP_ENCRYPTION_KEY;

export default function encryption(payloadData) {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(payloadData),
    encKey
  ).toString();
  let payload = {
    data: encryptedData,
  };
  return payload;
}
