import React from 'react'

const CountDownTimer = ({ remainingTime }) => {

    const [countDown, setCountDown] = React.useState(300);
    const [runTimer, setRunTimer] = React.useState(true);

    React.useEffect(() => {
        let timerId;

        if (runTimer) {
            setCountDown(300);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown - 1);
                
            }, 1000);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer]);

    React.useEffect(() => {
        if (countDown < 0 && runTimer) {
            console.log("expired");
            setRunTimer(false);
            setCountDown(0);
        }else{
            remainingTime(countDown)
        }
    }, [countDown, runTimer]);

    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

    return (
        <span>
            {minutes} {' min '}: {seconds}{' sec'}
        </span>
    )
}

export default CountDownTimer