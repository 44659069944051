import React from 'react';

const NotFound = (props) => {
    if (props.isLoggedIn) {
        return (
            <div className="RightArea">
                    {/* <div className="notFound-container"> */}
                    <div className="c-body">
                        <main className="c-main">
                            <div className="container-fluid">
                                <div className="fade-in text-center mt-5">
                                    <h1>404 Not Found !!! </h1>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            // </div>
        )
    } else {
        return window.location.href = `/`
    }


}


export default NotFound;