import React, { useState, useEffect } from 'react';
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import home from "../../assets/Ugroimages/sidebar/home.svg";
import list from "../../assets/Ugroimages/sidebar/list.svg";
import create from "../../assets/Ugroimages/sidebar/create.svg";
import search from "../../assets/Ugroimages/sidebar/search.png";
import logo from "../../assets/Ugroimages/logo.png";
import closeIcon from "../../assets/Ugroimages/close.svg";
import $ from "jquery";


const Sidebar = (props) => {
  const location = useLocation()
  let url = location.pathname.split("/").pop();

  const MenuClick=() => {
    $(".wrapper").toggleClass("active");
  }

  const MenuHide=() => {
    $(".wrapper").removeClass("active");
  }

  return (
    <div className="Sidebar-View">
      <div className="BodySection">
        <div onClick={MenuHide} className="wrapper">
          <nav id="sidebar" className="sidebar-container" >
            <div className='close-container'>
              <img src={logo} className="sidebar-logo" alt="" />
              <img src={closeIcon} onClick={MenuClick} className='close-btn' />
            </div>
            <Link to="/" className="decoration-none">
              <div onClick={MenuHide} className={"dropdown nav-container "+(url==='' ? 'active' : '')}>
                  <img
                    className="nav-icon"
                    src={home}
                  />
                  
                <div className="nav-link-container">
                  <button className="nav-link" type="button">
                    Dashboard
                  </button>
                </div>
              </div>
            </Link>
            <Link className="decoration-none" to="/applicant-details">
              <div onClick={MenuHide} className={"dropdown nav-container "+(url==='applicant-details' ? 'active' : '')} >
                  <img
                    className="nav-icon"
                    src={create}
                  />
                <div className="nav-link-container">
                  <button className="nav-link" type="button">
                  Create Case
                  </button>
                </div>
              </div>
            </Link>
            <Link className="decoration-none" to="/dashboard-card">
              <div onClick={MenuHide} className={"dropdown nav-container "+(url==='dashboard-card' ? 'active' : '')} >
                  <img
                    className="nav-icon"
                    src={list}
                  />
                <div className="nav-link-container">
                  <button className="nav-link" type="button">
                    List Cases
                  </button>
                </div>
              </div>
            </Link>
            <Link className="decoration-none" to="/search-case">
              <div onClick={MenuHide} className={"dropdown nav-container "+(url==='search-case' ? 'active' : '')} >
                  <img
                    className="nav-icon"
                    src={search}
                  />
                <div className="nav-link-container">
                  <button className="nav-link" type="button">
                    Search Cases
                  </button>
                </div>
              </div>
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
