const formattedCurrentDate = () => {
        let date = new Date().toLocaleString();

        let dateOnly = date.split(',')[0]
        let timeOnly = date.split(', ')[1]

        let year = dateOnly.split('/')[2];
        let month = dateOnly.split('/')[1];
        let day = dateOnly.split('/')[0];

        let hours = timeOnly.split(':')[0];
        let mins = timeOnly.split(':')[1];
        let secs = timeOnly.split(':')[2];

        let formattedDate = `${year}${month}${day}_${hours}${mins}${secs}`;
        return { formattedDate, year, month, day, hours, mins, secs };

};

export default formattedCurrentDate;