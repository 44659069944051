import React from "react";
import './style.scss';

const MainHeading = (props) => {
    return (
        <div className="dashboard-component">
            <div className="main-heading-background">
                <h3 className="page-main-heading">{props.heading}</h3>
            </div>
        </div>
    );
}

export default MainHeading;