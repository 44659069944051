import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SidebarHeader from "../screens/SidebarHeader";
import Login from '../screens/Auth/Customer/Login';
// import Otp from '../screens/Auth/Customer/Otp';
import NotFound from "../screens/NotFound/NotFound";
import Dashboard from "../screens/Dashboard";
import DashboardCard from "../screens/DashboardCard";
import SearchCase from "../screens/SearchCase";
import ApplicantDetails from "../screens/ApplicantDetails";
import IssueDetails from "../screens/IssueDetails";
import { ForgetPassword } from "../screens/Auth/Customer/ForgetPassword";
import ResetPassword from "../screens/Auth/Customer/ResetPassword";
// import { config } from "../config";
// import { PublicClientApplication } from '@azure/msal-browser';

function AppRoutes() {

    const isLoggedIn = sessionStorage.getItem('token');
    
    if (isLoggedIn) {
        return (
            <Router>
                <SidebarHeader />
                <Routes>
                    <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<Navigate replace to="/" />} />
                    <Route path={`${process.env.PUBLIC_URL}/`} element={<Dashboard />} />
                    <Route path={`${process.env.PUBLIC_URL}/dashboard-card`} element={<DashboardCard />} />
                    <Route path={`${process.env.PUBLIC_URL}/search-case`} element={<SearchCase />} />
                    <Route path={`${process.env.PUBLIC_URL}/applicant-details`} element={<ApplicantDetails />} />
                    <Route path={`${process.env.PUBLIC_URL}/issue-details/:id`} element={<IssueDetails />} />
                </Routes>
            </Router>
        );
    }
    else {
        return (
            <Router>
                <Routes>
                    <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} exact />
                    <Route path={`${process.env.PUBLIC_URL}/forget-password`} element={<ForgetPassword />} exact />
                    <Route path={`${process.env.PUBLIC_URL}/reset-password/:token`} element={<ResetPassword />} exact />
                    <Route path={'*'} element={<NotFound isLoggedIn={isLoggedIn} />} />
                </Routes>
            </Router>
        );
    }

}

export default AppRoutes;