export const GetProjectID=()=>{
   if (process.env.REACT_APP_ENV=='Production') return process.env.REACT_APP_PROJECT_ID_PROD; else return process.env.REACT_APP_PROJECT_ID_DEV;

}
export const GetBaseUrl=()=>{
   if (process.env.REACT_APP_ENV=='Production') return process.env.REACT_APP_API_BASE_PROD; else return process.env.REACT_APP_API_BASE_DEV;
}

export const GetLsqBaseUrl=()=>{
   if (process.env.REACT_APP_ENV=='Production') return process.env.REACT_APP_LSQ_API_BASE_PROD; else return process.env.REACT_APP_LSQ_API_BASE_DEV;
}

export const GetServiceTypeBaseUrl=()=>{
   if (process.env.REACT_APP_ENV=='Production') return process.env.REACT_APP_SERVICETYPE_PROD; else return process.env.REACT_APP_SERVICETYPE_DEV;
}

export const GetXApiKey=()=>{
   if (process.env.REACT_APP_ENV=='Production') return process.env.REACT_APP_API_KEY_PROD; else return process.env.REACT_APP_API_KEY_DEV;
}