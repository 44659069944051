import CryptoJS from "crypto-js";

const encKey = process.env.REACT_APP_ENCRYPTION_KEY;

export default function decryption(payloadData, path = null) {
    var atob = require("atob");
    var data = payloadData?.data;
    let encrypted = atob(data);
    encrypted = JSON.parse(encrypted);
    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    const value = encrypted.value;
    let key = CryptoJS.enc.Base64.parse(encKey);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
        iv: iv,
    });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    let newresponse = { ...payloadData, data: JSON.parse(decrypted) };
    return newresponse;
}
