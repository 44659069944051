import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from '../../../components/UI/Button/Button';
import LoginContainer from "../../../components/LoginContainer/LoginContainer";
import notify from "../../../shared/notify";
import './style.scss'
import { config } from "../../../config";
import { PublicClientApplication } from '@azure/msal-browser';
import CryptoJS from 'crypto-js'
import OTPInput from 'otp-input-react'
import CountDownTimer from "../../../Helper/CountDownTimer";
import { useStores } from "../../../stores";

const Login = () => {
  const navigate = useNavigate();
  const { applicantStore } = useStores();

  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const [loginType, setLoginType] = useState('sso')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false)
  const [showTimer, setShowTimer] = useState(false)
  const [timeLeft, setTimeLeft] = useState(300)

  const clientApplication = new PublicClientApplication({
    auth: {
      authority: config.authority,
      clientId: config.appId,
      redirectUri: config.redirectUri
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: true
    }
  })

  sessionStorage.removeItem('logRecordCount');

  const checkForLsq = async () => {
    applicantStore.checkIsLsqActive((response) => {
      sessionStorage.setItem('is_lsq_active', CryptoJS.AES.encrypt(`${response?.status}`, `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
    })
  }

  const login = async () => {

    clientApplication.loginPopup({
      scopes: config.scopes,
      prompt: "select_account"
    }).then(
      (response) => {

        sessionStorage.setItem('token', CryptoJS.AES.encrypt(response.accessToken, `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
        sessionStorage.setItem('name', CryptoJS.AES.encrypt(response.account.name.split(" ")[0], `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
        sessionStorage.setItem('email', CryptoJS.AES.encrypt(response.account.username, `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
        window.location.reload();

        // setIsAuthenticated(true)
      }
    ).catch(
      (error) => {
        // setIsAuthenticated(false)
        setError(error)
        setUser({})
      }
    )
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (process.env.REACT_APP_ENV === 'Development') {
      sessionStorage.setItem('token', CryptoJS.AES.encrypt('asjklsfdakljklsfdmewejkn', `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
      sessionStorage.setItem('name', CryptoJS.AES.encrypt('pallavi', `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
      sessionStorage.setItem('email', CryptoJS.AES.encrypt('pallavi-cs@ugrocapital.com', `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
      sessionStorage.setItem('is_lsq_active', CryptoJS.AES.encrypt('true', `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
      window.location.reload();
    }
    else {
      await checkForLsq()
      await login()
      await clientApplication.initialize()
    }

  }

  const handleChangeLoginType = () => {
    setEmail('')
    setPassword('')
    if (loginType == 'email') {
      setLoginType('sso')
    } else {
      setLoginType('email')
    }
  }

  const handleForgetPassword = () => {
    navigate("/forget-password")
  }

  const handleLoginWithEmail = () => {
    if (email === '') {
      notify('Please enter email', 'danger');
      return;
    }
    if (password === '') {
      notify('Please enter password', 'danger');
      return;
    }
    setLoading(true)
    let requestData = {
      "email": email,
      "password": password
    }
    applicantStore.loginWithEmail(requestData, (response) => {
      if (response?.success) {
        checkForLsq()
        notify(`${response?.message}`, 'success')
        setLoginType('otp')
        setShowTimer(true)
      } else {
        notify(`${response?.message}`, 'danger')
      }
      setLoading(false)
    })
  }

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (otp === '') {
      notify('Please enter OTP', 'danger');
      return;
    }
    if (otp.length < 6) {
      notify('OTP is too short', 'danger');
      return;
    }
    setLoading(true)
    let requestData = {
      "email": email,
      "otp": otp
    }
    applicantStore.verifyLoginWithEmail(requestData, (response) => {
      if (response?.success) {
        notify(`${response?.message}`, 'success')
        sessionStorage.setItem('logintype', CryptoJS.AES.encrypt('Email', `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
        sessionStorage.setItem('token', CryptoJS.AES.encrypt(`${response?.access_token}`, `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
        sessionStorage.setItem('name', CryptoJS.AES.encrypt(`${response?.user_name}`, `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
        sessionStorage.setItem('email', CryptoJS.AES.encrypt(`${response?.user_email}`, `${process.env.REACT_APP_ENCRYPTION_KEY}`).toString())
        window.location.reload()
      } else {
        notify(`${response?.message}`, 'danger')
      }
      setLoading(false)
    })
    setLoading(false)
  }

  const handleResendOtp = () => {
    let requestData = {
      "email": email
    }
    applicantStore.loginWithEmailSendOtp(requestData, (response) => {
      if (response?.success) {
        notify(`${response?.message}`, 'success')
        setShowTimer(true)
        setTimeLeft(300)
      } else {
        notify(`${response?.error}`, 'danger')
      }
      setLoading(false)
    })
  }

  const getRemaningTime = (time) => {
    setTimeLeft(time)
  }

  return (
    <Fragment>
      <LoginContainer>
        <div className="login-box">

          <h3 className="login-heading">Log In to Your <span>UGRO</span> Account</h3>
          {
            loginType == 'email' &&
            <div>
              <div className='form-group mt-3'>
                <label>Email</label><tt className="text-danger">*</tt>
                <input
                  value={email}
                  type='email'
                  onChange={e => setEmail(e.target.value)}
                  className='form-control'
                  placeholder={'Email'}
                />
              </div>
              <div className='form-group mt-3'>
                <label>Password</label><tt className="text-danger">*</tt>
                <div className="d-flex justify-content-center align-items-center">
                  <input
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={e => setPassword(e.target.value)}
                    className='form-control'
                    placeholder={'Password'}
                  />
                  <i
                    onClick={() => setShowPassword(!showPassword)}
                    className={`fa ${!showPassword ? "fa-eye-slash" : "fa-eye"}`}
                    style={{ zIndex: '999', position: 'absolute', right: '105px' }}
                  ></i>
                </div>
              </div>
              <div className="d-flex justify-content-end my-auto">
                <div style={{ cursor: 'pointer', fontSize: '14px', color: '#00b5ff' }}>
                  <p onClick={handleForgetPassword}>Forget Password ?</p>
                </div>
              </div>
              <div className="mt-4 text-center">
                <Button
                  type="submit"
                  buttonStyle="btn--secondary--solid"
                  buttonSize="btn-medium"
                  onClick={handleLoginWithEmail}
                >
                  {!loading ? 'Login' : <div className="loading-spinner" style={{ width: '20px', height: '20px' }}></div>}
                </Button>
              </div>
              <div className="mt-2 d-flex justify-content-center align-items-center" style={{ fontSize: '14px' }}>
                <i class="fa fa-arrow-left" style={{ cursor: 'pointer', color: '#ec743f' }}></i><p style={{ cursor: 'pointer', color: '#ec743f', marginLeft: '5px' }} onClick={handleChangeLoginType}>Back</p>
              </div>
            </div>
          }
          {
            loginType == 'otp' &&
            <div>
              <p className="sub-heading mt-5 text-center">Please enter the OTP</p>
              <OTPInput
                className="otp-boxes"
                otpType="number"
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={6}
                disabled={false}
              />
              <div className="d-flex justify-content-center">
                <div className="mr-2 mt-24">
                  <Button
                    type="submit"
                    buttonStyle="btn--secondary--solid"
                    buttonSize="btn-medium"
                    onClick={handleVerifyOtp}
                  >{!loading ? 'Verify' : <div className="loading-spinner" style={{ width: '20px', height: '20px' }}></div>}</Button>
                </div>

                {timeLeft == 0 &&
                  <div className="mt-24">
                    <Button
                      type="button"
                      buttonStyle="btn--secondary--outline"
                      buttonSize="btn-medium"
                      onClick={handleResendOtp}
                    >Resend OTP</Button>
                  </div>
                }

              </div>
              {(showTimer && timeLeft > 0) &&
                <div className="text-center mt-3" style={{ fontSize: '12px' }}>
                  {'OTP not received yet ? Resend In '}
                  <CountDownTimer
                    remainingTime={getRemaningTime}
                  />
                </div>
              }
            </div>
          }
          {loginType == 'sso' &&
            <form
              onSubmit={(e) => {
                e.key === 'Enter' || handleLogin(e);

              }}
            >
              <div className="mt-24 text-center">
                <Button
                  type="submit"
                  buttonStyle="btn--secondary--solid"
                  buttonSize="btn-medium"
                >
                  Continue with Microsoft
                </Button>
              </div>
              <div className="mt-3 text-center" style={{ fontSize: '14px', color: '#00b5ff' }}>
                <p className="d-inline" onClick={handleChangeLoginType} style={{ cursor: 'pointer' }}>Login with Email</p>
              </div>
            </form>
          }
        </div>
      </LoginContainer>
    </Fragment>
  )
}

export default Login;
