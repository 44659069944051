import React, { Fragment, useState } from 'react'
import LoginContainer from '../../../components/LoginContainer/LoginContainer'
import Button from '../../../components/UI/Button/Button'
import { useNavigate } from 'react-router-dom'
import { useStores } from '../../../stores'
import notify from '../../../shared/notify'

export const ForgetPassword = () => {

    const navigate = useNavigate();
    const { applicantStore } = useStores();

    const [email, setEmail] = useState('')
    const [showTempPassText, setShowTempPassText] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSendEmail = () => {
        if (email === '') {
            notify('Please enter email', 'danger');
            return;
        }
        setLoading(true)
        let requestData = {
            "email": email
        }
        applicantStore.sendResetLinkToEmail(requestData, (response) => {
            if (response?.success) {
                notify(`${response?.message}`, 'success')
                setShowTempPassText(true)
                setLoading(false)
                setTimeout(() => {
                    navigate('/')
                }, 5000);
            } else {
                notify(`${response?.message}`, 'danger')
                setLoading(false)
            }
            setLoading(false)
        })
    }

    const handleCancel = (e) => {
        e.preventDefault();
        navigate('/')
    }
    return (
        <Fragment>
            <LoginContainer>
                <div className='login-box mx-auto'>
                    <h2 className="top-heading text-center">Forget Password</h2>
                    {showTempPassText &&
                        <div className='p-3 rounded' style={{ backgroundColor: '#e2ffe8' }}>
                            <p>
                                We have send you the password reset link on your Email Id.
                            </p>
                        </div>
                    }
                    <div className='form-group mt-3'>
                        <label>Email</label><tt className="text-danger">*</tt>
                        <input
                            value={email}
                            type='email'
                            onChange={e => setEmail(e.target.value)}
                            className='form-control'
                            placeholder={'Email'}
                        />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="mr-2 mt-24">
                            <Button
                                type="submit"
                                buttonStyle="btn--secondary--solid"
                                buttonSize="btn-medium"
                                onClick={handleSendEmail}
                            >
                                {!loading ? 'Send Email' : <div className="loading-spinner" style={{ width: '20px', height: '20px' }}></div>}
                            </Button>
                        </div>
                        <div className="mt-24">
                            <Button
                                type="button"
                                buttonStyle="btn--secondary--outline"
                                buttonSize="btn-medium"
                                onClick={handleCancel}
                            >Cancel</Button>
                        </div>
                    </div>
                </div>
            </LoginContainer>
        </Fragment>
    )
}
